/**
 * @generated SignedSource<<6a09973af90fa60681c6094ffec025f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "holesWonTeamA",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "holesWonTeamB",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "teamA",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "teamB",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasScores",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchplayLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MatchplayLeaderboardTable_tournament"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchplayLeaderboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPairs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamplayConfiguration",
            "kind": "LinkedField",
            "name": "teamplayConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colorA",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colorB",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamplayScore",
                "kind": "LinkedField",
                "name": "overallTeamplayScore",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamplayTeam",
                "kind": "LinkedField",
                "name": "teamA",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamplayTeam",
                "kind": "LinkedField",
                "name": "teamB",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "round",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Matchplay",
                "kind": "LinkedField",
                "name": "rankedMatchplays",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchplayType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatchplayHole",
                    "kind": "LinkedField",
                    "name": "matchplayHoles",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strokesA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strokesB",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strokesC",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "strokesD",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hole",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatchplayPlayer",
                    "kind": "LinkedField",
                    "name": "matchplayPlayers",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Player",
                        "kind": "LinkedField",
                        "name": "player",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "teamplay",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamplayScore",
                "kind": "LinkedField",
                "name": "teamplayScore",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tournament",
                "kind": "LinkedField",
                "name": "tournament",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TournamentRound",
                    "kind": "LinkedField",
                    "name": "tournamentRounds",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87bd5fce562f8533ce244adfadea114f",
    "id": null,
    "metadata": {},
    "name": "MatchplayLeaderboardQuery",
    "operationKind": "query",
    "text": "query MatchplayLeaderboardQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...MatchplayLeaderboardTable_tournament\n    id\n  }\n}\n\nfragment MatchplayLeaderboardTableEntryHoles_matchplay on Matchplay {\n  matchplayType\n  matchplayHoles {\n    score\n    strokesA\n    strokesB\n    strokesC\n    strokesD\n  }\n}\n\nfragment MatchplayLeaderboardTableEntry_matchplay on Matchplay {\n  ...MatchplayLeaderboardTableEntryHoles_matchplay\n  matchplayType\n  name\n  score\n  startDate\n  matchplayPlayers {\n    position\n    player {\n      firstName\n      lastName\n      id\n    }\n    id\n  }\n  matchplayHoles {\n    hole\n    score\n  }\n}\n\nfragment MatchplayLeaderboardTable_tournament on Tournament {\n  hasPairs\n  teamplayConfiguration {\n    colorA\n    colorB\n    overallTeamplayScore {\n      holesWonTeamA\n      holesWonTeamB\n      teamA\n      teamB\n    }\n    teamA {\n      name\n      id\n    }\n    teamB {\n      name\n      id\n    }\n    id\n  }\n  tournamentRounds {\n    hasScores\n    round\n    status\n    rankedMatchplays {\n      ...MatchplayLeaderboardTableEntry_matchplay\n      databaseId\n      teamplay\n      id\n    }\n    teamplayScore {\n      holesWonTeamA\n      holesWonTeamB\n      teamA\n      teamB\n    }\n    tournament {\n      tournamentRounds {\n        hasScores\n        id\n      }\n      id\n    }\n    id\n  }\n  id\n}\n"
  }
};
})();

node.hash = "ea25c25f625afca2d2d5e0937a55dfa5";

module.exports = node;
