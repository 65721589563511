import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import RelayEnvironment from './RelayEnvironment';
import routes from './routes';
import RoutingContext from './routing/RoutingContext';
import createRouter from './routing/createRouter';
import RouterRenderer from './routing/RouteRenderer';

import LoadingScreen from './routing/LoadingScreen';
import './i18n';

const router = createRouter(routes);

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://aee71a697c344332bcde88d5c08e5bff@o4504915492864000.ingest.sentry.io/4504915543261184',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <Suspense fallback={<LoadingScreen />}>
        <RoutingContext.Provider value={router.context}>
          <RouterRenderer />
        </RoutingContext.Provider>
      </Suspense>
    </RelayEnvironmentProvider>
  </StrictMode>
);
