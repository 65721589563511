export const reducer = (state, { payload, type }) => {
  switch (type) {
    case 'onBlur':
      return { ...state, focusItem: {}, focus: false };
    case 'onFocus':
      return { ...state, focus: true };
    case 'moveUp': {
      const items = state.filteredItems.filter((fi) => !payload.selectedItemIds.includes(fi.databaseId));
      const index = items.findIndex((i) => i.databaseId === state.focusItem.databaseId);

      if (index !== 0) {
        const item = index > 0 ? items[index - 1] : items[items.length - 1];
        return { ...state, focusItem: item };
      } else {
        return state;
      }
    }
    case 'moveDown': {
      const items = state.filteredItems.filter((fi) => !payload.selectedItemIds.includes(fi.databaseId));
      const index = items.findIndex((i) => i.databaseId === state.focusItem.databaseId);

      if (index < items.length - 1) {
        const item = index >= 0 ? items[index + 1] : items[0];
        return { ...state, focusItem: item };
      } else {
        return state;
      }
    }
    case 'inputChange':
      return { ...state, focusItem: {}, ...payload };
    default:
      throw new Error(`Unknown action: ${type}`);
  }
};
