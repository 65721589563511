import styled from 'styled-components';
import FormInput, { StyledInput } from '../Form/Input';
import { Btn } from '../Button/Button';

export const Container = styled(StyledInput)`
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  ${({ focus }) => focus && 'border-color: var(--primary-color);'}
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  height: auto;
  padding: 0 2rem 0 0.75rem;
`;

export const List = styled.ul`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ced4da;
  list-style: none;
  margin: 3px auto 0;
  max-height: calc(5 * 24px);
  min-width: 96%;
  overflow: scroll;
  padding: 0;
  position: absolute;
  z-index: 10;
`;

export const Item = styled.li`
  ${({ focus }) => focus && 'background-color: var(--primary-color); color: var(--primary-color-text);'}
  padding: 0 0.75rem;

  &:hover {
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
`;

export const Input = styled(FormInput)`
  border: none;
  padding: 0;
  outline: none;
`;

export const Badge = styled.div`
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  padding: 0 0.5rem 0 0;
`;

Badge.Label = styled.span`
  overflow: hidden;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

Badge.Button = styled(Btn)`
  color: var(--secondary-color);
  background-color: transparent;
  padding: 0;
`;
