import Dropdown from './Dropdown';
import { Menu, Item, Button, Text, TextTruncate } from './Components';

Dropdown.TextTruncate = TextTruncate;
Dropdown.Menu = Menu;
Dropdown.Menu.Item = Item;
Dropdown.Menu.Item.Button = Button;
Dropdown.Menu.Text = Text;

export default Dropdown;
