import styled from 'styled-components';

const gutterWidth = 15;

export const Container = styled.div`
  padding-right: ${gutterWidth}px;
  padding-left: ${gutterWidth}px;
  margin-right: auto;
  margin-left: auto;
  ${(props) =>
    props.fluid === undefined &&
    `
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
  `}
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    ${({ noMargin }) => !noMargin && 'margin-right: -15px; margin-left: -15px;'};
  }

  ${({ baseline }) => baseline && 'align-items: baseline;'}
  ${({ center }) => center && 'align-items: center;'}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}rem;`}
  ${({ spacer }) => `
  ${Col} {
    padding-left: ${gutterWidth * spacer}px; padding-right: ${gutterWidth * spacer}px;`}
  }
`;

const numberOfColumns = 12;

export const Col = styled.div`
  @media (min-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${({ center }) => center && 'display: flex; align-items: center; justify-content: center;'}
  ${({ left }) => left && 'display: flex;'}
  ${({ right }) => right && 'display: flex; justify-content: flex-end;'}

  ${(props) =>
    props.xs
      ? `
  flex: ${props.xs ? `1 1 ${(props.xs / numberOfColumns) * 100}%` : '1 1 auto'};
  max-width: ${((props.xs || numberOfColumns) / numberOfColumns) * 100}%;
    `
      : `
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  `}

  ${(props) => props.offsetXs && `margin-left: ${(props.offsetXs / numberOfColumns) * 100}%;`};

  @media (min-width: 576px) {
    ${(props) =>
      props.sm &&
      `
        flex: ${props.sm ? `1 1 ${(props.sm / numberOfColumns) * 100}%` : '1 1 auto'};
        max-width: ${((props.sm || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetSm && `margin-left: ${(props.offsetSm / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 768px) {
    ${(props) =>
      props.md &&
      `
        flex: ${props.md ? `1 1 ${(props.md / numberOfColumns) * 100}%` : '1 1 auto'};
        max-width: ${((props.md || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetMd && `margin-left: ${(props.offsetMd / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 992px) {
    ${(props) =>
      props.lg &&
      `
        flex: ${props.lg ? `1 1 ${(props.lg / numberOfColumns) * 100}%` : '1 1 auto'};
        max-width: ${((props.lg || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetLg && `margin-left: ${(props.offsetLg / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 1200px) {
    ${(props) =>
      props.xl &&
      `
        flex: ${props.xl ? `1 1 ${(props.xl / numberOfColumns) * 100}%` : '1 1 auto'};
        max-width: ${((props.xl || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetXl && `margin-left: ${(props.offsetXl / numberOfColumns) * 100}%;`};
  }

  @media (min-width: 1400px) {
    ${(props) =>
      props.xxl &&
      `
        flex: ${props.xxl ? `1 1 ${(props.xxl / numberOfColumns) * 100}%` : '1 1 auto'};
        max-width: ${((props.xxl || numberOfColumns) / numberOfColumns) * 100}%;
      `};

    ${(props) => props.offsetXxl && `margin-left: ${(props.offsetXxl / numberOfColumns) * 100}%;`};
  }
`;
