import React, { useRef } from 'react';
import { enGB, nl } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import { Form, IconButton } from '../../birdiebase-design-system';

registerLocale('nl', nl);
registerLocale('en', enGB);

const StyledDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    box-shadow: 0px 6px 14px -6px var(--shadow-color);
  }

  .react-datepicker__header {
    background-color: var(--card-bgcolor);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background-color: var(--primary-color) !important;
  }

  .react-datepicker__previous-next-icon {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
  }

  .react-datepicker__previous-next-button {
    border: 0;
    background-color: var(--card-bgcolor);
    padding: 0;
  }

  .react-datepicker__previous-button:hover,
  .react-datepicker__next-button:hover {
    background-color: var(--body-bgcolor);
  }

  li.react-datepicker__time-list-item {
    &:hover {
      background-color: var(--body-bgcolor) !important;
      margin-right: 1px;
    }
    &--selected:hover {
      background-color: var(--primary-color) !important;
    }
  }

  .react-datepicker__day:hover {
    background-color: var(--body-bgcolor);
  }

  .react-datepicker__popper-width-time {
    min-width: 22.5rem;
  }

  .react-datepicker__popper-width-notime {
    min-width: 17rem;
  }

  .react-datepicker__button-area {
    display: flex;
    margin: 0 0.625rem;
    justify-content: space-between;
    gap: 1rem;

    & > span {
      flex: 1;
    }
  }

  .datepicker-inputfield-calendar-icon {
    align-items: center;
    display: flex;
  }

  .fa-calendar {
    color: var(--primary-color);
    cursor: pointer;
    margin-left: -1.7rem;
    position: relative;
  }
`;

const StyledIcon = styled(IconButton)`
  display: contents;
`;

const Datepicker = (props) => {
  const { i18n, t } = useTranslation('dateTimePicker');
  const value = isNaN(Date.parse(props.date)) ? null : new Date(props.date);

  const datePickerRef = useRef(null);

  const handleChange = (newValue) => {
    props.onChange(props.attribute, newValue);
  };

  const setFocus = () => {
    datePickerRef.current && datePickerRef.current.setFocus();
  };

  return (
    <StyledDatePicker className={props.className}>
      {props.label && (
        <Form.Label htmlFor={props.attribute} tooltipText={props.tooltipText}>
          {props.label}
        </Form.Label>
      )}
      <div className="datepicker-inputfield-calendar-icon">
        <DatePicker
          calendarStartDay={1}
          customInput={<Form.Input field={props.attribute} errors={props.errors} style={props.inputStyle} />}
          dateFormat={`${props.showTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy'}`}
          disabled={props.disabled}
          id={props.id}
          locale={i18n.language}
          minDate={props.minDate}
          onBlur={props.onBlur}
          onChange={handleChange}
          placeholderText={props.showTime ? t('placeholderDateTime') : t('placeholderDate')}
          popperClassName={`${
            props.showTime ? 'react-datepicker__popper-width-time' : 'react-datepicker__popper-width-notime'
          }`}
          ref={datePickerRef}
          renderCustomHeader={({
            monthDate,
            decreaseMonth,
            increaseMonth,
            decreaseYear,
            increaseYear,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            prevYearButtonDisabled,
            nextYearButtonDisabled,
          }) => (
            <div className="react-datepicker__button-area">
              <button
                aria-label={t('previousYear')}
                className="react-datepicker__previous-button react-datepicker__previous-next-button"
                disabled={prevYearButtonDisabled}
                onClick={decreaseYear}
                type="button"
              >
                <span className="react-datepicker__previous-next-icon">{'<<'}</span>
              </button>

              <button
                aria-label={t('previousMonth')}
                className="react-datepicker__previous-button react-datepicker__previous-next-button"
                disabled={prevMonthButtonDisabled}
                onClick={decreaseMonth}
                type="button"
              >
                <span className="react-datepicker__previous-next-icon">{'<'}</span>
              </button>

              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString(i18n.language, { month: 'long', year: 'numeric' })}
              </span>

              <button
                aria-label={t('nextMonth')}
                className="react-datepicker__next-button react-datepicker__previous-next-button"
                disabled={nextMonthButtonDisabled}
                onClick={increaseMonth}
                type="button"
              >
                <span className="react-datepicker__previous-next-icon">{'>'}</span>
              </button>

              <button
                aria-label={t('nextYear')}
                className="react-datepicker__next-button react-datepicker__previous-next-button"
                disabled={nextYearButtonDisabled}
                onClick={increaseYear}
                type="button"
              >
                <span className="react-datepicker__previous-next-icon">{'>>'}</span>
              </button>
            </div>
          )}
          selected={value}
          showWeekNumbers
          showTimeSelect={props.showTime}
          timeCaption={t('time')}
          timeFormat="HH:mm"
          timeIntervals={15}
        />
        {props.errors.filter((e) => e.field === props.attribute).length === 0 && (
          <StyledIcon onClick={setFocus} type="button">
            <FontAwesomeIcon icon={faCalendar} />
          </StyledIcon>
        )}
      </div>
    </StyledDatePicker>
  );
};

Datepicker.defaultProps = {
  disabled: false,
  errors: [],
  showTime: false,
  tooltipText: null,
};

Datepicker.propTypes = {
  attribute: PropTypes.string.isRequired,
  date: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
  id: PropTypes.string,
  inputStyle: PropTypes.object,
  label: PropTypes.string,
  minDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  showTime: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default Datepicker;
