import styled from 'styled-components';

import logo from '../logo.png';

const Logo = styled.img`
  margin-bottom: 1rem;
  padding-left: 15.1724px;

  @media (max-width: 768px) {
    width: 100%;
  }

  transform: scale(1);
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.75;
      transform: scale(0.95);
    }

    70% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0.75;
      transform: scale(0.95);
    }
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const LoadingScreen = () => {
  return (
    <Wrapper>
      <Logo alt="BirdieBase" src={logo} width="400" />
    </Wrapper>
  );
};

export default LoadingScreen;
