/**
 * @generated SignedSource<<b2791b373fcce40b8473ee15371019aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rankingType",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "score",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tournamentId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RankingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Ranking",
        "kind": "LinkedField",
        "name": "ranking",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "RankingTable_ranking"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamplayRankingTable_ranking"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RankingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Ranking",
        "kind": "LinkedField",
        "name": "ranking",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rankingTournamentsCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RankingPlayer",
            "kind": "LinkedField",
            "name": "rankingPlayers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Player",
                "kind": "LinkedField",
                "name": "player",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "databaseId",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nationality",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previousPosition",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previousScore",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roundsPlayed",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RankingPlayerRound",
                "kind": "LinkedField",
                "name": "rankingPlayerRounds",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isIncluded",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "sortedTournaments",
            "args": [
              {
                "kind": "Literal",
                "name": "sortBy",
                "value": "start_date"
              },
              {
                "kind": "Literal",
                "name": "sortDirection",
                "value": "asc"
              }
            ],
            "concreteType": "RankingTournament",
            "kind": "LinkedField",
            "name": "rankingTournaments",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": "rankingTournaments(sortBy:\"start_date\",sortDirection:\"asc\")"
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasActiveTournament",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RankedTeamplay",
            "kind": "LinkedField",
            "name": "rankedTeamplays",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gameDiff",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gamesLost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gamesWon",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchDiff",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchesLost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "matchesWon",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "played",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "points",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tied",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "won",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81f7a23e7375a1f73af65721e6ea1047",
    "id": null,
    "metadata": {},
    "name": "RankingQuery",
    "operationKind": "query",
    "text": "query RankingQuery(\n  $category: String\n  $id: ID!\n) {\n  ranking(id: $id) {\n    rankingType\n    ...RankingTable_ranking_15BdcN\n    ...TeamplayRankingTable_ranking\n    id\n  }\n}\n\nfragment RankingTableEntry_ranking on Ranking {\n  sortedTournaments: rankingTournaments(sortBy: \"start_date\", sortDirection: \"asc\") {\n    id\n    tournamentId\n  }\n}\n\nfragment RankingTableEntry_rankingPlayer on RankingPlayer {\n  position\n  previousPosition\n  previousScore\n  roundsPlayed\n  score\n  player {\n    category\n    firstName\n    lastName\n    nationality\n    id\n  }\n  rankingPlayerRounds {\n    isIncluded\n    tournamentId\n    score\n    id\n  }\n}\n\nfragment RankingTable_ranking_15BdcN on Ranking {\n  rankingTournamentsCount\n  rankingPlayers(category: $category) {\n    position\n    player {\n      databaseId\n      name\n      id\n    }\n    ...RankingTableEntry_rankingPlayer\n    id\n  }\n  ...RankingTableEntry_ranking\n  id\n}\n\nfragment TeamplayRankingTable_ranking on Ranking {\n  hasActiveTournament\n  rankedTeamplays {\n    gameDiff\n    gamesLost\n    gamesWon\n    lost\n    matchDiff\n    matchesLost\n    matchesWon\n    name\n    played\n    points\n    position\n    tied\n    won\n  }\n}\n"
  }
};
})();

node.hash = "5984522d96edff6ce0338a37b133aa36";

module.exports = node;
