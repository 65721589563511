import styled from 'styled-components';
import { Btn } from '../Button/Button';

export const Backdrop = styled.div`
  inset: 0;
  position: fixed;
  overflow: scroll;
  z-index: 9999;
`;

export const Wrapper = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 6px 14px -6px var(--shadow-color);
  overflow: hidden;
  padding: 0 1rem;
  position: absolute;
  z-index: 9999;
`;

export const Menu = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
`;

export const Item = styled.div`
  border-top: 1px solid var(--tertiary-color);
  &:hover {
    background-color: var(--tertiary-color);
  }
`;

export const Button = styled(Btn).attrs({ type: 'button' })`
  text-align: left;
  white-space: nowrap;
  width: 100%;
`;

export const Text = styled.div`
  line-height: 2.5rem;
  padding: 0 1rem;
`;

export const TextTruncate = styled.span`
  display: inline-block;
  max-width: 20vw;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
