import styled from 'styled-components';

import { Btn } from './Button';

const IconButton = styled(Btn)`
  color: ${({ variant }) => `var(--${variant}-color)`};
  ${({ size }) => size === undefined && 'border: 2px solid transparent;'}

  &:hover {
    ${({ variant }) =>
      `color: hsl(var(--${variant}-color-h), var(--${variant}-color-s), calc(var(--${variant}-color-l) - 20%));`}
  }
`;

export default IconButton;
