import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { Btn } from '../Button/Button';

const Button = styled(Btn).attrs({ type: 'button' })`
  background-color: transparent;
  display: flex;
  font-weight: 600;
  padding: 0;
`;

const Carets = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.6rem;
  margin-left: 0.5rem;
  margin-top: 0.1rem;

  span:last-child {
    margin-top: -0.2rem;
  }
`;

const Caret = styled.span`
  color: var(--${({ active }) => (active ? 'primary' : 'secondary')}-color);
  height: 10px;
`;

const SortButton = ({ children, name, onClick, sortDirection }) => {
  return (
    <Button onClick={() => onClick(name)}>
      {children}
      <Carets>
        <Caret active={sortDirection === 'asc'}>
          <FontAwesomeIcon icon={faCaretUp} />
        </Caret>
        <Caret active={sortDirection === 'desc'}>
          <FontAwesomeIcon icon={faCaretDown} />
        </Caret>
      </Carets>
    </Button>
  );
};

SortButton.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
};

export default SortButton;
