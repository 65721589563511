import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ValidationMessage from './ValidationMessage';

export const StyledInput = styled.input`
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--secondary-color);
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + 1.5rem);
  line-height: 1.5;
  padding: 0.625rem 0.75rem;
  transition: all 0.15s ease-in-out;
  transition-property: border-color;
  width: 100%;

  ${(props) =>
    props.hasErrors &&
    `
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      border-color: var(--danger-color);
      padding-right: calc(1.5em + 0.75rem);
  `}

  &:focus {
    border-color: var(--primary-color);
    outline: 0;
  }

  &::placeholder {
    color: hsl(218, 24%, 52%);
    opacity: 0.5;
  }

  &:disabled {
    background-color: var(--body-bgcolor);
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Input = React.forwardRef(({ errors, ...props }, ref) => {
  const error = errors.find((e) => e.field === (props.field || props.name));

  return (
    <>
      <StyledInput autoComplete="off" {...props} hasErrors={error !== undefined} ref={ref} />
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </>
  );
});

Input.defaultProps = {
  errors: [],
};

Input.propTypes = {
  name: PropTypes.string,
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
};

export default Input;
