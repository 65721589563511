import styled from 'styled-components';
import { Card, Grid } from '../../birdiebase-design-system';
import { GlobalStyle } from '../../birdiebase-design-system/shared/global';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import LoadingScreen from '../../routing/LoadingScreen';

import { getWithExpiry, setWithExpiry } from './withExpiry';

const Background = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

let reload = false;

const ErrorInfo = ({ error }) => {
  if (error?.message) {
    if (!getWithExpiry('chunk_failed')) {
      setWithExpiry('chunk_failed', 'true', 10000);
      window.location.reload();
      reload = true;
    }
  }

  return (
    <>
      {reload ? (
        <LoadingScreen />
      ) : (
        <>
          <GlobalStyle />
          <Background>
            <Grid.Container>
              <Grid.Row>
                <Grid.Col offsetSm="1" sm="10" offsetLg="3" lg="6" offsetXxl="3.5" xxl="5">
                  <Card>
                    <h1>
                      <FontAwesomeIcon icon={faTriangleExclamation} /> We're sorry, but something went wrong.
                    </h1>
                    <a href="/">Back to home</a>
                  </Card>
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          </Background>
        </>
      )}
    </>
  );
};

export default ErrorInfo;
