import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';

import Progressbar from '../Progressbar/Progressbar';
import { LinkButton } from '../Button';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const StyledLinkButton = styled(LinkButton)`
  white-space: nowrap;
`;

const Label = styled.span`
  color: var(--primary-color);
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FileUploadProgress = ({ hideDelete, hideProgress, name, onClick, progress, textButton, url }) => {
  return (
    <Wrapper>
      {url ? (
        <Label as="a" href={url} target="_blank" download rel="noreferrer">
          <FontAwesomeIcon icon={faFile} /> {name}
        </Label>
      ) : (
        <Label>
          <FontAwesomeIcon icon={faFile} /> {name}
        </Label>
      )}
      {!hideProgress && <Progressbar progress={progress} hideLabel={progress >= 100} />}
      {progress >= 100 && !hideDelete && (
        <StyledLinkButton onClick={onClick} size="xs" type="button" variant="secondary">
          <FontAwesomeIcon icon={faTimes} /> {textButton}
        </StyledLinkButton>
      )}
    </Wrapper>
  );
};

FileUploadProgress.defaultProps = {
  textButton: 'Delete',
};

FileUploadProgress.propTypes = {
  hideDelete: PropTypes.bool,
  hideProgress: PropTypes.bool,
  name: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  textButton: PropTypes.string,
  url: PropTypes.string,
};

export default FileUploadProgress;
