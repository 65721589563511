export const MATCHPLAY_COLORS = [
  { background: '0, 74, 133', color: 'var(--white)', name: 'blue' },
  { background: '173, 150, 87', color: 'var(--white)', name: 'gold' },
  { background: '184, 134, 11', color: 'var(--white)', name: 'darkgoldenrod' },
  { background: '210, 105, 30', color: 'var(--white)', name: 'orange' },
  { background: '209, 53, 35', color: 'var(--white)', name: 'red' },
  { background: '0, 128, 0', color: 'var(--white)', name: 'green' },
  { background: '114, 206, 246', color: 'var(--black)', name: 'lightblue' },
  { background: '35, 53, 119', color: 'var(--white)', name: 'france' },
  { background: '255, 155, 0', color: 'var(--black)', name: 'holland' },
  { background: '188, 0, 45', color: 'var(--white)', name: 'japan' },
  {
    backgroundImage:
      'linear-gradient(to bottom, #B31942 0%, #B31942 11.11%, white 0, white 22.22%, #B31942 0, #B31942 33.33%, white 0, white 44.44%, #B31942 0, #B31942 55.55%, white 0, white 66.66%, #B31942 0, #B31942 77.77%, white 0, white 88.88%, #B31942 0)',
    background: '179, 25, 66',
    color: 'var(--white)',
    imageColor: 'var(--black)',
    name: 'usa',
  },
  {
    background: '108, 172, 228',
    color: 'var(--white)',
    name: 'argentina',
  },
];

export const MP_BACKGROUND_A = '116, 143, 61';
export const MP_BACKGROUND_B = '0, 66, 0';

export const MP_COLOR_A = 'var(--white)';
export const MP_COLOR_B = 'var(--white)';

export const FLIGHT_STATUSES = ['active', 'finished', 'closed'];

export const MATCHPLAY_OPTIONS = ['fourball', 'foursome', 'single'];

export const PLAYER_CATEGORIES = ['55+', 'juniors', 'men', 'seniors', 'women'];

export const WEIGHTED_CATEGORIES = PLAYER_CATEGORIES.map((category) => {
  switch (category) {
    case 'men':
      return { category, weight: 100 };
    case 'women':
      return { category, weight: 90 };
    case 'junior':
      return { category, weight: 80 };
    case '55+':
      return { category, weight: 70 };
    case 'seniors':
      return { category, weight: 60 };
    default:
      return { category, weight: 50 };
  }
});

export const PLAYER_GENDERS = ['female', 'male'];

export const RANKING_TYPES = [
  'bestRecovery',
  'mostParBreakers',
  'mostParOrBetter',
  'mostBirdies',
  'mostEagles',
  'mostPars',
  'birdieOrBetterStreak',
  'bogeyFreeStreak',
  'parThree',
  'parFour',
  'parFive',
  'frontNine',
  'backNine',
  'scoringAverage',
];

export const SUSPENSE_CONFIG = { timeoutMs: 2000 };

export const STAT_TYPES = [
  'totalHolesPlayed',
  'holeInOnes',
  'albatrosses',
  'eagles',
  'birdies',
  'pars',
  'bogeys',
  'doubleBogeys',
  'tripleBogeys',
];

export const TOURNAMENT_ROUND_STATUSES = ['in_preparation', 'active', 'finished'];
