/**
 * @generated SignedSource<<65d4f4b48299f42129b84fc8ae3579d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organisationId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "courseId",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sortBy",
    "variableName": "sortBy"
  },
  {
    "kind": "Variable",
    "name": "sortDirection",
    "variableName": "sortDirection"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseLayoutsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseOrganisation",
            "kind": "LinkedField",
            "name": "courseOrganisations",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewCourseLayoutModal_course"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CourseLayoutList_organisation"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewCourseLayoutModal_organisation"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "CourseLayoutList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseLayoutsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Course",
        "kind": "LinkedField",
        "name": "course",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseOrganisation",
            "kind": "LinkedField",
            "name": "courseOrganisations",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CourseLayoutPagination",
        "kind": "LinkedField",
        "name": "courseLayoutPagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseLayout",
            "kind": "LinkedField",
            "name": "courseLayouts",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "courseId",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f993789cfa36985eab3ff9a73e00cf5",
    "id": null,
    "metadata": {},
    "name": "CourseLayoutsQuery",
    "operationKind": "query",
    "text": "query CourseLayoutsQuery(\n  $id: ID!\n  $limit: Int\n  $organisationId: ID!\n  $page: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n  $status: String\n) {\n  course(id: $id) {\n    __typename\n    organisationId\n    courseOrganisations {\n      organisationId\n      id\n    }\n    ...NewCourseLayoutModal_course\n    id\n  }\n  organisation(id: $organisationId) {\n    ...CourseLayoutList_organisation\n    ...NewCourseLayoutModal_organisation\n    id\n  }\n  ...CourseLayoutList_query_40MPTX\n}\n\nfragment CourseLayoutList_organisation on Organisation {\n  databaseId\n  ...CourseLayout_organisation\n}\n\nfragment CourseLayoutList_query_40MPTX on Query {\n  course(id: $id) {\n    name\n    id\n  }\n  courseLayoutPagination(courseId: $id, limit: $limit, page: $page, query: $query, sortBy: $sortBy, sortDirection: $sortDirection, status: $status) {\n    courseLayouts {\n      id\n      ...CourseLayout_courseLayout\n    }\n    totalCount\n  }\n}\n\nfragment CourseLayout_courseLayout on CourseLayout {\n  courseId\n  databaseId\n  name\n}\n\nfragment CourseLayout_organisation on Organisation {\n  databaseId\n}\n\nfragment NewCourseLayoutModal_course on Course {\n  databaseId\n}\n\nfragment NewCourseLayoutModal_organisation on Organisation {\n  databaseId\n}\n"
  }
};
})();

node.hash = "9a9e2d58aa719ade4d43792028633e25";

module.exports = node;
