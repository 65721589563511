/**
 * @generated SignedSource<<b65b494bf2329e16c9302182139b56a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v3 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v4 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 25
  },
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationMembersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganisationAdmins_organisation"
          },
          {
            "args": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "OrganisationPlayers_organisation"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "OrganisationPlayers_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationMembersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "role",
                "value": "admin"
              }
            ],
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "members",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "superAdmin",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "members(role:\"admin\")"
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "PlayerOrganisationConnection",
            "kind": "LinkedField",
            "name": "playerOrganisations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlayerOrganisationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlayerOrganisation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasAutomaticRenewal",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Player",
                        "kind": "LinkedField",
                        "name": "player",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v6/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v9/*: any*/),
            "filters": [
              "query",
              "sortBy",
              "sortDirection"
            ],
            "handle": "connection",
            "key": "OrganisationPlayerList_playerOrganisations",
            "kind": "LinkedHandle",
            "name": "playerOrganisations"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a64eb619e5313842c3afd46a341115f",
    "id": null,
    "metadata": {},
    "name": "OrganisationMembersQuery",
    "operationKind": "query",
    "text": "query OrganisationMembersQuery(\n  $id: ID!\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n) {\n  organisation(id: $id) {\n    ...OrganisationAdmins_organisation\n    ...OrganisationPlayers_organisation_3hhBpg\n    id\n  }\n  ...OrganisationPlayers_query\n}\n\nfragment InviteAdminModal_organisation on Organisation {\n  id\n  databaseId\n}\n\nfragment NewPlayerMembershipModal_query on Query {\n  users {\n    id\n    email\n  }\n}\n\nfragment OrganisationAdmin_member on Member {\n  id\n  status\n  user {\n    email\n    name\n    superAdmin\n    id\n  }\n}\n\nfragment OrganisationAdmins_organisation on Organisation {\n  members(role: \"admin\") {\n    id\n    ...OrganisationAdmin_member\n  }\n  ...InviteAdminModal_organisation\n}\n\nfragment OrganisationPlayerList_organisation_3hhBpg on Organisation {\n  playerOrganisations(first: 25, query: $query, sortBy: $sortBy, sortDirection: $sortDirection) {\n    edges {\n      cursor\n      node {\n        ...OrganisationPlayer_playerOrganisation\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment OrganisationPlayer_playerOrganisation on PlayerOrganisation {\n  endDate\n  hasAutomaticRenewal\n  id\n  startDate\n  player {\n    name\n    user {\n      email\n      status\n      id\n    }\n    id\n  }\n}\n\nfragment OrganisationPlayers_organisation_3hhBpg on Organisation {\n  ...OrganisationPlayerList_organisation_3hhBpg\n}\n\nfragment OrganisationPlayers_query on Query {\n  ...NewPlayerMembershipModal_query\n}\n"
  }
};
})();

node.hash = "73f3bd44274d0a2fe8e03d9a35cdef5d";

module.exports = node;
