import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledValidationMessage = styled.div`
  color: var(--danger-color);
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
`;

const ValidationMessage = ({ errors }) =>
  errors.map((error) => <StyledValidationMessage key={error}>{error}</StyledValidationMessage>);

ValidationMessage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ValidationMessage;
