import styled from 'styled-components';
import { Btn } from '../Button/Button';

const InputGroup = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }
`;

const Text = styled.span`
  background-color: var(--tertiary-color);
  padding: 0.625rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
`;

InputGroup.Button = Btn;
InputGroup.Text = Text;

export default InputGroup;
