/**
 * @generated SignedSource<<21195ec41893c5037e258154db0fa8cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPairs",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPairsPlus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "info",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "matchplay",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "teamStrokeplay",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useReservation",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "courseLayoutId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasScores",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentSetupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DestroyTournament_tournament"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TournamentSetupRound_tournamentRound"
              },
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TournamentSetupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "courseId",
                "storageKey": null
              },
              (v11/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organisation",
                "kind": "LinkedField",
                "name": "organisation",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCourses",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "holeInOneHole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "longestDriveHole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nearyHole",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasChallenges",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shotgun",
                "storageKey": null
              },
              (v2/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "round",
                "storageKey": null
              },
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeBetweenFlights",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "course",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "withHoles",
                        "value": true
                      }
                    ],
                    "concreteType": "CourseLayout",
                    "kind": "LinkedField",
                    "name": "courseLayouts",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": "courseLayouts(withHoles:true)"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Flight",
                "kind": "LinkedField",
                "name": "flights",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v13/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tournament",
                "kind": "LinkedField",
                "name": "tournament",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tournamentRoundsCount",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90ae528f740bddc874395e3529fbaffc",
    "id": null,
    "metadata": {},
    "name": "TournamentSetupQuery",
    "operationKind": "query",
    "text": "query TournamentSetupQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...DestroyTournament_tournament\n    databaseId\n    hasPairs\n    hasPairsPlus\n    id\n    info\n    matchplay\n    name\n    teamStrokeplay\n    useReservation\n    tournamentRounds {\n      ...TournamentSetupRound_tournamentRound\n      courseLayoutId\n      databaseId\n      id\n    }\n  }\n}\n\nfragment ChangeCourseModal_tournamentRound on TournamentRound {\n  courseId\n  courseLayoutId\n  id\n  organisation {\n    databaseId\n    hasCourses\n    ...CourseFilter_organisation\n    id\n  }\n}\n\nfragment CourseFilter_organisation on Organisation {\n  hasCourses\n}\n\nfragment DestroyTournament_tournament on Tournament {\n  hasScores\n  id\n}\n\nfragment TournamentRoundChallenges_tournamentRound on TournamentRound {\n  holeInOneHole\n  id\n  longestDriveHole\n  nearyHole\n}\n\nfragment TournamentRoundHasChallenges_tournamentRound on TournamentRound {\n  id\n  hasChallenges\n}\n\nfragment TournamentRoundShotgun_tournamentRound on TournamentRound {\n  id\n  shotgun\n}\n\nfragment TournamentSetupRound_tournamentRound on TournamentRound {\n  ...ChangeCourseModal_tournamentRound\n  ...TournamentRoundChallenges_tournamentRound\n  ...TournamentRoundHasChallenges_tournamentRound\n  ...TournamentRoundShotgun_tournamentRound\n  courseLayoutId\n  databaseId\n  hasChallenges\n  hasScores\n  id\n  round\n  startDate\n  status\n  timeBetweenFlights\n  course {\n    name\n    courseLayouts(withHoles: true) {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n  flights {\n    databaseId\n    startDate\n    id\n  }\n  tournament {\n    tournamentRoundsCount\n    id\n  }\n}\n"
  }
};
})();

node.hash = "031dddb46639a7a90ce7b483a39ebb47";

module.exports = node;
