import { useState } from 'react';
import { ContentState, EditorState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor as StyledEditor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';

import ValidationMessage from './ValidationMessage';

const Editor = styled(StyledEditor)``;

const Wrapper = styled.div`
  .rdw-editor-wrapper {
    .rdw-editor-toolbar {
      border: 1px solid #ced4da;
      border-radius: 8px 8px 0 0;
      margin-bottom: 0;
    }

    .rdw-editor-main {
      border: 1px solid #ced4da;
      border-top: none;
      border-radius: 0 0 8px 8px;
      padding: 0.25rem 1rem;
    }
  }
`;

const TextEditor = ({ errors, ...props }) => {
  const error = errors.find((e) => e.field === (props.field || props.name));

  const blocksFromHTML = convertFromHTML(props.value || '');
  const initialState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(initialState));

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);

    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    props.onChange({ target: { name: 'info', value: value === '<p></p>\n' ? null : value } });
  };

  return (
    <>
      <Wrapper>
        <Editor
          defaultContentState={props.value}
          editorState={editorState}
          onBlur={props.onBlur}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ['inline', 'link', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline'],
            },
            link: {
              inDropdown: false,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link', 'unlink'],
            },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </Wrapper>
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </>
  );
};

export default TextEditor;
