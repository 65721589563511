import styled from 'styled-components';

const Card = styled.div`
  background-color: var(--card-bgcolor);

  ${({ padding }) => padding && 'padding: 1rem;'};
  ${({ paddingBottom }) => paddingBottom && 'padding-bottom: 1rem;'};

  @media (min-width: 1024px) {
    padding: 1rem;
    border-radius: 0.75rem;
    box-shadow: 0 0.5rem 1rem -0.5rem var(--shadow-color);
    margin-bottom: 1.5rem;
  }

  ${({ ignoreScreenSize }) =>
    ignoreScreenSize &&
    `
    padding: 1rem;
    border-radius: 0.75rem;
    box-shadow: 0 0.5rem 1rem -0.5rem var(--shadow-color);
    margin-bottom: 1.5rem;
  `}
`;

const Footer = styled.div`
  border-top: 1px solid var(--tertiary-color);
  padding-top: 0.75rem;'
`;

const Header = styled.div`
  align-items: center;
  background-color: var(--card-bgcolor);
  display: flex;
  font-weight: 500;

  padding-top: 1rem;
  margin-bottom: 1rem;

  ${({ border }) => border && 'border-bottom: 1px solid var(--tertiary-color); padding-bottom: 0.75rem;'}

  ${({ baseline }) => baseline && 'align-items: baseline;'}
  ${({ center }) => center && 'justify-content: center;'}
  ${({ noPaddingBottom }) => noPaddingBottom && 'padding-bottom: 0;'}
  ${({ noPaddingTop }) => noPaddingTop && 'padding-top: 0;'}
  ${({ spaced }) => !spaced && 'justify-content: center;'};
  ${({ spaceAround }) => spaceAround && 'justify-content: space-between; padding 1rem;'}

  ${({ column }) =>
    column &&
    `
      flex-direction: column;
      align-items: center;

      @media (min-width: 1024px) {
        align-items: flex-start;
      }
    `};

  @media (min-width: 1024px) {
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
`;

const Subtitle = styled.div`
  font-size: 1rem;
  font-weight: 400;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

const Title = styled.div`
  font-size: 1.5rem;

  @media (max-width: 480px) {
    text-align: center;

    ${({ textLeft }) => textLeft && 'text-align: left;'}
  }
`;

Card.Footer = Footer;
Card.Header = Header;
Card.Header.Subtitle = Subtitle;
Card.Header.Title = Title;

export default Card;
