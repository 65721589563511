import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const ProgressBar = styled.div`
  border-radius: 4px;
  display: flex;
  flex: 1;
  height: 0.5rem;
  margin-right: 1rem;
  overflow: hidden;
`;

const Bar = styled.div`
  background-color: var(--primary-color);
  transition: width 0.6s ease;
  ${({ progress }) => `width: ${progress}%;`}
`;

const Label = styled.span`
  color: var(--primary-color);
  font-size: 80%;
`;

const Progress = ({ hideLabel, progress }) => (
  <Container>
    <ProgressBar>
      <Bar
        ariaValueMax="100"
        ariaValuemin="0"
        ariaValuenow={progress}
        progress={progress}
        role="progressbar"
      />
    </ProgressBar>
    {!hideLabel && <Label>{progress}%</Label>}
  </Container>
);

Progress.propTypes = {
  hideLabel: PropTypes.bool,
  progress: PropTypes.number.isRequired,
};

export default Progress;
