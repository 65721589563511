/**
 * @generated SignedSource<<15288ac06d49ee5e27329703b294253d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTeamplay",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "round",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "defaultColor",
    "storageKey": null
  },
  (v6/*: any*/),
  (v3/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Player",
  "kind": "LinkedField",
  "name": "player",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchplayRoundsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MatchplayHeader_tournament"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MatchplayRound_tournament"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamplayConfiguration",
            "kind": "LinkedField",
            "name": "teamplayConfiguration",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MatchplayRound_tournamentRound"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchplayRoundsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TeamplayConfiguration",
            "kind": "LinkedField",
            "name": "teamplayConfiguration",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colorA",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "colorB",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamAId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamBId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamplayTeam",
                "kind": "LinkedField",
                "name": "teamA",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TeamplayTeam",
                "kind": "LinkedField",
                "name": "teamB",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentPlayer",
            "kind": "LinkedField",
            "name": "tournamentPlayers",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FlightScore",
                "kind": "LinkedField",
                "name": "flightScores",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Matchplay",
                "kind": "LinkedField",
                "name": "matchplays",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matchplayType",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MatchplayPlayer",
                    "kind": "LinkedField",
                    "name": "matchplayPlayers",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FlightScore",
                        "kind": "LinkedField",
                        "name": "flightScore",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasScores",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "position",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Flight",
                "kind": "LinkedField",
                "name": "flights",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlightScore",
                    "kind": "LinkedField",
                    "name": "flightScores",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "playerId",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "238a568d85ee3076e2dc8ddf0016b855",
    "id": null,
    "metadata": {},
    "name": "MatchplayRoundsQuery",
    "operationKind": "query",
    "text": "query MatchplayRoundsQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...MatchplayHeader_tournament\n    ...MatchplayRound_tournament\n    hasTeamplay\n    id\n    teamplayConfiguration {\n      databaseId\n      id\n    }\n    tournamentRounds {\n      ...MatchplayRound_tournamentRound\n      round\n      id\n    }\n  }\n}\n\nfragment MatchplayHeader_tournament on Tournament {\n  id\n  hasTeamplay\n  teamplayConfiguration {\n    colorA\n    colorB\n    teamAId\n    teamBId\n    teamA {\n      databaseId\n      defaultColor\n      name\n      id\n    }\n    teamB {\n      databaseId\n      defaultColor\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment MatchplayRound_tournament on Tournament {\n  hasTeamplay\n  teamplayConfiguration {\n    colorA\n    colorB\n    id\n  }\n  tournamentPlayers {\n    flightScores {\n      databaseId\n      id\n    }\n    player {\n      databaseId\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment MatchplayRound_tournamentRound on TournamentRound {\n  databaseId\n  matchplays {\n    id\n    matchplayType\n    name\n    status\n    matchplayPlayers {\n      databaseId\n      flightScore {\n        hasScores\n        id\n      }\n      player {\n        databaseId\n        name\n        id\n      }\n      position\n      id\n    }\n  }\n  ...Matchplay_tournamentRound\n}\n\nfragment Matchplay_tournamentRound on TournamentRound {\n  flights {\n    databaseId\n    flightScores {\n      playerId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

node.hash = "6746512d19a9375be70bd740623beb26";

module.exports = node;
