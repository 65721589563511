/**
 * @generated SignedSource<<0b20f39f6b71bdcbd97378a80c8abc59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideFlights",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "round",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shotgun",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startingHole",
  "storageKey": null
},
v10 = [
  (v8/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Player",
  "kind": "LinkedField",
  "name": "player",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v13 = [
  (v8/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeeTimesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LeaderboardSearch_tournament"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Flight",
                "kind": "LinkedField",
                "name": "flights",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlightScore",
                    "kind": "LinkedField",
                    "name": "flightScores",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pair",
                        "kind": "LinkedField",
                        "name": "pair",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Player",
                            "kind": "LinkedField",
                            "name": "playerA",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Player",
                            "kind": "LinkedField",
                            "name": "playerB",
                            "plural": false,
                            "selections": (v10/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TournamentPlayer",
                        "kind": "LinkedField",
                        "name": "tournamentPlayer",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeeTimesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "tournament",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentRound",
            "kind": "LinkedField",
            "name": "tournamentRounds",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/),
              (v3/*: any*/),
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Flight",
                "kind": "LinkedField",
                "name": "flights",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlightScore",
                    "kind": "LinkedField",
                    "name": "flightScores",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Pair",
                        "kind": "LinkedField",
                        "name": "pair",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Player",
                            "kind": "LinkedField",
                            "name": "playerA",
                            "plural": false,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Player",
                            "kind": "LinkedField",
                            "name": "playerB",
                            "plural": false,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TournamentPlayer",
                        "kind": "LinkedField",
                        "name": "tournamentPlayer",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cc74c2cb44a1c6b843a684344c3f976",
    "id": null,
    "metadata": {},
    "name": "TeeTimesQuery",
    "operationKind": "query",
    "text": "query TeeTimesQuery(\n  $id: ID!\n) {\n  tournament(id: $id) {\n    ...LeaderboardSearch_tournament\n    tournamentRounds {\n      hideFlights\n      id\n      round\n      shotgun\n      startDate\n      status\n      flights {\n        id\n        name\n        startingHole\n        startDate\n        flightScores {\n          id\n          pair {\n            playerA {\n              name\n              id\n            }\n            playerB {\n              name\n              id\n            }\n            id\n          }\n          player {\n            id\n            name\n          }\n          tournamentPlayer {\n            category\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment LeaderboardSearch_tournament on Tournament {\n  ...RoundSelector_tournament\n}\n\nfragment RoundSelector_tournament on Tournament {\n  tournamentRounds {\n    round\n    status\n    id\n  }\n}\n"
  }
};
})();

node.hash = "4f3be41ec2f0c00481a29f2195e98b3e";

module.exports = node;
