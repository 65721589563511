import { createPortal } from 'react-dom';
import Sheet from 'react-modal-sheet';
import styled from 'styled-components';

const Dialog = styled.div`
  inset: 0;
  overflow: scroll;
  position: absolute;
  z-index: 1040;
`;

const SheetContent = styled(Sheet.Content)`
  padding: 1rem;
`;

export default function SheetModal(props) {
  return createPortal(
    <Sheet detent="content-height" isOpen={props.isOpen} onClose={props.onClose} tweenConfig={{ duration: 0.25 }}>
      <Dialog role="dialog">
        <Sheet.Container>
          <Sheet.Header />
          <SheetContent>{props.children}</SheetContent>
        </Sheet.Container>
        <Sheet.Backdrop onClick={props.onClose} />
      </Dialog>
    </Sheet>,
    document.body
  );
}
