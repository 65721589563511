/**
 * @generated SignedSource<<174e7b58b870ac65076a3bd751ccc134>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortDirection"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "statuses"
  }
],
v1 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "countryCode"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "Variable",
  "name": "organisationId",
  "variableName": "organisationId"
},
v4 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v5 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v6 = {
  "kind": "Variable",
  "name": "sortDirection",
  "variableName": "sortDirection"
},
v7 = {
  "kind": "Variable",
  "name": "statuses",
  "variableName": "statuses"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewTournamentModal_user"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "TournamentsHeader_query"
      },
      {
        "args": [
          (v1/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "TournamentListRefetch_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "TournamentList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TournamentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultOrganisationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisations",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasCourses",
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "favoriteCourse",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseLayout",
                    "kind": "LinkedField",
                    "name": "courseLayouts",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeCountryCodes",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v1/*: any*/),
          {
            "kind": "Literal",
            "name": "hasTournaments",
            "value": true
          }
        ],
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisations",
        "plural": true,
        "selections": [
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "TournamentConnection",
        "kind": "LinkedField",
        "name": "tournaments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TournamentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tournament",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organisationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "playersCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "organisation",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "filters": [
          "countryCode",
          "organisationId",
          "query",
          "sortBy",
          "sortDirection",
          "statuses"
        ],
        "handle": "connection",
        "key": "TournamentList_tournaments",
        "kind": "LinkedHandle",
        "name": "tournaments"
      }
    ]
  },
  "params": {
    "cacheID": "c306bd7b593224d30ad08684536c0ff1",
    "id": null,
    "metadata": {},
    "name": "TournamentsQuery",
    "operationKind": "query",
    "text": "query TournamentsQuery(\n  $countryCode: String\n  $organisationId: Int\n  $query: String\n  $sortBy: String\n  $sortDirection: String\n  $statuses: [String!]\n) {\n  currentUser {\n    ...NewTournamentModal_user\n    id\n  }\n  ...TournamentsHeader_query_1uGpiX\n  ...TournamentListRefetch_query_1Dimkd\n  ...TournamentList_query_1uGpiX\n}\n\nfragment CourseFilter_organisation on Organisation {\n  hasCourses\n}\n\nfragment NewTournamentModal_user on User {\n  defaultOrganisationId\n  organisations {\n    databaseId\n    hasCourses\n    name\n    favoriteCourse {\n      databaseId\n      courseLayouts {\n        databaseId\n        id\n      }\n      id\n    }\n    ...CourseFilter_organisation\n    id\n  }\n}\n\nfragment TournamentFragment_tournament on Tournament {\n  ...TournamentLink_tournament\n  id\n  name\n  playersCount\n  startDate\n  status\n  organisation {\n    name\n    id\n  }\n}\n\nfragment TournamentLink_tournament on Tournament {\n  __typename\n  databaseId\n  id\n  organisationId\n  status\n}\n\nfragment TournamentListRefetch_query_1Dimkd on Query {\n  tournaments(countryCode: $countryCode, first: 10, organisationId: $organisationId, query: $query, sortBy: $sortBy, sortDirection: $sortDirection, statuses: $statuses) {\n    edges {\n      cursor\n      node {\n        ...TournamentFragment_tournament\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TournamentList_query_1uGpiX on Query {\n  ...TournamentsHeader_query_1uGpiX\n}\n\nfragment TournamentsHeader_query_1uGpiX on Query {\n  activeCountryCodes\n  currentUser {\n    id\n  }\n  organisations(countryCode: $countryCode, hasTournaments: true) {\n    databaseId\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "17a60d9daa5e1081f1d54ecec7f76703";

module.exports = node;
