import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledAlert = styled.div.attrs(() => ({ role: 'alert' }))`
  background-position: left calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 0.5rem 1rem 0.5rem 2rem;
  margin-bottom: 1rem;

  ${({ variant }) => {
    let image;
    switch (variant) {
      case 'primary':
        image =
          '"data:image/svg+xml,%3Csvg width=\'20\' height=\'21\' viewBox=\'0 0 20 21\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg clip-path=\'url(%23clip0)\'%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5ZM11 6.49997H8.99997V4.49997L11 4.49997V6.49997ZM11 16.5H8.99997L8.99997 8.49997L11 8.49997L11 16.5Z\' fill=\'%232665E6\'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=\'clip0\'%3E%3Crect width=\'20\' height=\'20\' fill=\'white\' transform=\'translate(0 0.5)\'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"';
        break;
      case 'success':
        image =
          '"data:image/svg+xml,%3Csvg width=\'20\' height=\'21\' viewBox=\'0 0 20 21\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg clip-path=\'url(%23clip0)\'%3E%3Ccircle cx=\'10\' cy=\'10.5\' r=\'10\' fill=\'%2337B44C\'/%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M15.5442 8.39549L14.3152 7.16675L8.7937 12.687L6.05512 9.94903L4.82611 11.1778L7.5647 13.9158L7.56468 13.9158L8.79369 15.1445L15.5442 8.39549Z\' fill=\'white\'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id=\'clip0\'%3E%3Crect width=\'20\' height=\'20\' fill=\'white\' transform=\'translate(0 0.5)\'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A"';
        break;
      default:
        image =
          '"data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'12\' height=\'12\' fill=\'none\' stroke=\'%23dc3545\' viewBox=\'0 0 12 12\'%3e%3ccircle cx=\'6\' cy=\'6\' r=\'4.5\'/%3e%3cpath stroke-linejoin=\'round\' d=\'M5.8 3.6h.4L6 6.5z\'/%3e%3ccircle cx=\'6\' cy=\'8.2\' r=\'.6\' fill=\'%23dc3545\' stroke=\'none\'/%3e%3c/svg%3e"';
    }
    return `
      background-color: hsl(var(--${variant}-color-h), var(--${variant}-color-s), 97%);
      background-image: url(${image});
      border-color: var(--${variant}-color);
      color: var(--${variant}-color);
    `;
  }}
`;

const Alert = (props) => <StyledAlert {...props} />;

Alert.defaultProps = {
  variant: 'danger',
};

Alert.propTypes = {
  variant: PropTypes.oneOf(['primary', 'success', 'danger']),
};

export default Alert;
