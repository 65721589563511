import styled from 'styled-components';

const InnerCard = styled.div`
  background-color: #edf5f5;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1rem;

  ${({ center }) => center && 'text-align: center;'}
  ${({ header }) => header && 'font-weight: 800;'}
`;

const Header = styled.div`
  font-weight: 800;
  margin-bottom: 1rem;
`;

const Title = styled.h5`
  font-size: 1.125rem;
  margin-bottom: 0;
`;

InnerCard.Header = Header;
InnerCard.Header.Title = Title;

export default InnerCard;
