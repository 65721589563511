/**
 * @generated SignedSource<<106c68fa5762969206c911e03644de59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentAbility",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MobileNavigation_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MobileTopbar_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Topbar_query"
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "currentTournament",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Flight",
        "kind": "LinkedField",
        "name": "currentFlight",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "verifiedCode",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentLocale",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPlayer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "membersCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "organisations",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "superAdmin",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Tournament",
        "kind": "LinkedField",
        "name": "currentTournament",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7830cb994c2cc0577ca50b5a68e2b81",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery {\n  ...MobileNavigation_query\n  ...MobileTopbar_query\n  ...Topbar_query\n  currentAbility\n  currentTournament {\n    databaseId\n    id\n  }\n}\n\nfragment MobileNavigation_query on Query {\n  currentFlight {\n    verifiedCode\n    id\n  }\n}\n\nfragment MobileTopbar_query on Query {\n  currentLocale\n  currentUser {\n    ...UserDropdown_user\n    databaseId\n    id\n  }\n}\n\nfragment Topbar_query on Query {\n  currentLocale\n  currentUser {\n    name\n    superAdmin\n    ...UserDropdown_user\n    id\n  }\n}\n\nfragment UserDropdown_user on User {\n  hasPlayer\n  membersCount\n  playerId\n  organisations {\n    databaseId\n    id\n  }\n}\n"
  }
};
})();

node.hash = "cd16c8a0c0f068414439c508ba9eee24";

module.exports = node;
