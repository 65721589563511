import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StyledInput } from './Input';
import ValidationMessage from './ValidationMessage';

const StyledTextArea = styled(StyledInput)`
  height: auto;
`;

const TextArea = ({ errors, ...props }) => {
  const error = errors.find((e) => e.field === (props.field || props.name));

  return (
    <>
      <StyledTextArea
        as={'textarea'}
        hasErrors={error !== undefined}
        {...props}
      />
      {error !== undefined && <ValidationMessage errors={error.messages} />}
    </>
  );
};

TextArea.defaultProps = {
  errors: [],
};

TextArea.propTypes = {
  name: PropTypes.string,
  field: (props, propName, componentName) => {
    if (
      props.errors.length > 0 &&
      (props.name === undefined || typeof props.name !== 'string') &&
      (props.field === undefined || typeof props.field !== 'string')
    ) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Field is required when errors are present`
      );
    }
  },
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      messages: PropTypes.array,
    })
  ),
};

export default TextArea;
