const black = '#121212';
const backgroundColor = 'hsl(80, 40%, 40%, 0.15)';
const darkGrey = '#676767';
const grey = '#BCBCBC';
const lightGrey = '#ECECEC';
const white = '#FCFCFC';

export const theme = {
  black,
  backgroundColor,
  darkGrey,
  grey,
  lightGrey,
  shadow: 'hsla(222, 52%, 19%, 0.4)',
  white,

  primary: {
    colorH: '80',
    colorS: '40%',
    colorL: '40%',
    color: white,
  },
  secondary: {
    colorH: '120',
    colorS: '100%',
    colorL: '13%',
    color: white,
  },
  tertiary: {
    colorH: '90',
    colorS: '40%',
    colorL: '88%',
    color: 'hsl(220, 79%, 53%)',
  },
  quaternary: {
    colorH: '180',
    colorS: '40%',
    colorL: '40%',
    color: white,
  },
  success: {
    colorH: '75',
    colorS: '36%',
    colorL: '50%',
    color: white,
  },
  danger: {
    colorH: '15',
    colorS: '46%',
    colorL: '50%',
    color: white,
  },
  warning: {
    colorH: '48',
    colorS: '100%',
    colorL: '50%',
    color: white,
  },

  doubleBogey: '#000',
  doubleBogeyText: white,
  bogey: '#A3A3A3',
  bogeyText: white,
  par: '#E0E0E0',
  parText: black,
};
