import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { Backdrop, Wrapper } from './Components';

const Dropdown = ({ anchorEl, children, onClose, right }) => {
  const backdrop = useRef();
  const wrapper = useRef();

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) onClose();
  };

  const onOutSideClick = ({ target }) => {
    if (backdrop.current === target) onClose();
  };

  useEffect(() => {
    if (anchorEl === null) return;

    const setPosition = () => {
      const { height, top, left, width } = anchorEl.getBoundingClientRect();
      wrapper.current.style.top = `${top + height}px`;

      if (right) {
        wrapper.current.style.left = `${left + width - wrapper.current.getBoundingClientRect().width}px`;
      } else {
        wrapper.current.style.left = `${left}px`;
      }
    };

    setPosition();
    window.addEventListener('resize', setPosition);
    return () => window.removeEventListener('resize', setPosition);
  }, [anchorEl, right]);

  if (anchorEl === null) return null;

  return createPortal(
    <Backdrop ref={backdrop} onClick={onOutSideClick} onKeyDown={onKeyDown}>
      <Wrapper ref={wrapper}>{children}</Wrapper>
    </Backdrop>,
    document.body
  );
};

Dropdown.defaultProps = {
  right: false,
};

Dropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  right: PropTypes.bool,
};

export default Dropdown;
