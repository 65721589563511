import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ children, ...props }) => {
  const onSubmit = (event) => {
    event.preventDefault();
    props.onSubmit && props.onSubmit(event);
  };

  return (
    <form noValidate {...props} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onSubmit: PropTypes.func,
};

export default Form;
