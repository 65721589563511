/**
 * @generated SignedSource<<70fea6fcf8f2a0283ccd6008a9e0e9f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "recordId",
      "variableName": "id"
    },
    {
      "kind": "Literal",
      "name": "recordType",
      "value": "Organisation"
    }
  ],
  "kind": "ScalarField",
  "name": "isFavorite",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Organisation",
  "kind": "LinkedField",
  "name": "organisation",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganisationCoursesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseOrganisation",
            "kind": "LinkedField",
            "name": "courseOrganisations",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "args": (v1/*: any*/),
                "kind": "FragmentSpread",
                "name": "CourseOrganisation_courseOrganisation"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "managedCourses",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "args": (v1/*: any*/),
                "kind": "FragmentSpread",
                "name": "Course_course"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewCourseModal_organisation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganisationCoursesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseOrganisation",
            "kind": "LinkedField",
            "name": "courseOrganisations",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Course",
                "kind": "LinkedField",
                "name": "course",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Course",
            "kind": "LinkedField",
            "name": "managedCourses",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organisationIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organisationId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "houseNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zipCode",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultCountryCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49f07ffeffb45b4a198ecc0090985379",
    "id": null,
    "metadata": {},
    "name": "OrganisationCoursesQuery",
    "operationKind": "query",
    "text": "query OrganisationCoursesQuery(\n  $id: ID!\n) {\n  organisation(id: $id) {\n    databaseId\n    id\n    courseOrganisations {\n      id\n      ...CourseOrganisation_courseOrganisation_1Bmzm5\n    }\n    managedCourses {\n      id\n      ...Course_course_1Bmzm5\n    }\n    ...NewCourseModal_organisation\n  }\n}\n\nfragment CourseManagersModalBody_course on Course {\n  organisationId\n}\n\nfragment CourseManagersModal_course on Course {\n  id\n  organisationIds\n  ...CourseManagersModalBody_course\n}\n\nfragment CourseModal_course on Course {\n  id\n  name\n  address {\n    city\n    country\n    houseNumber\n    street\n    zipCode\n    id\n  }\n}\n\nfragment CourseOrganisation_courseOrganisation_1Bmzm5 on CourseOrganisation {\n  id\n  course {\n    databaseId\n    id\n    isFavorite(recordId: $id, recordType: \"Organisation\")\n    name\n  }\n  organisation {\n    databaseId\n    id\n  }\n}\n\nfragment Course_course_1Bmzm5 on Course {\n  databaseId\n  id\n  isFavorite(recordId: $id, recordType: \"Organisation\")\n  name\n  organisation {\n    databaseId\n    id\n  }\n  ...CourseManagersModal_course\n  ...CourseModal_course\n}\n\nfragment NewCourseModal_organisation on Organisation {\n  databaseId\n  defaultCountryCode\n}\n"
  }
};
})();

node.hash = "d7eb8c9fb689c32dfee83ce3f22adeba";

module.exports = node;
